<template>
  <sdPageHeader title="Participants">
    <template v-slot:subTitle>
         <span class="date-label">Showing all {{totalData}} participants for {{campaignName}}</span>
    </template>
  </sdPageHeader>
  <Main>
    <sdCards headless>
      <a-row :gutter="15">
        <a-col :xs="24">
          <TopToolBox>
            <a-row :gutter="15" class="justify-content-between">
              <a-col :lg="6" :xs="24">
                <div class="table-search-box">
                  <SearchInputStyled
                    style="width: 100%;"
                    class="certain-category-search"
                    placeholder="Search Participants"
                    @search="onSearching"
                  >
                    <a-input>
                      <template #suffix>
                        <sdFeatherIcons type="search"/>
                      </template>
                    </a-input>
                  </SearchInputStyled>
                </div>
              </a-col>
              <a-col :xxl="14" :lg="16" :xs="24">
                <div class="table-toolbox-menu">
                  <span class="toolbox-menu-title"> Status:</span>
                  <a-radio-group @click="handleChangeForFilter" v-model:value="status">
                    <a-radio-button value="">All</a-radio-button>
                    <a-radio-button
                      v-for="filter in [...new Set(filterKey)]"
                      :key="filter.value"
                      :value="filter.value"
                    >
                      {{ filter.label }}
                    </a-radio-button>
                  </a-radio-group>
                </div>
              </a-col>
            </a-row>
          </TopToolBox>
        </a-col>
      </a-row>
      <a-row :gutter="15">
        <a-col :md="24">
          <TableWrapper class="table-order table-responsive">
            <a-table
              rowKey="id"
              :columns="fields"
              :dataSource="subscribers"
              @change="handleTableChange"
              :pagination="{ pageSize: perPage, showSizeChanger: false, total: totalData}"
            >
            <template #is_winner="{text: is_winner}">
              <span :class="['status', getStatusColor(is_winner)]">{{participant_status[is_winner]}}</span>
            </template>
            <template #created_date="{text: created_date}">
              <div v-if="created_date">
                {{ getFormatedDate(created_date) }}
              </div>
            </template>
            <template #action="{record}">
              <div class="table-actions">
                <sdButton @click="navigateTo(`/${initial}/participants/setup`)" class="btn-icon" type="info" shape="circle">
                  <sdFeatherIcons type="edit" size="16" />
                </sdButton>
                <sdButton @click="onRemovePress(record.encrypt_id)" class="btn-icon" type="info" shape="circle">
                  <sdFeatherIcons type="trash-2" size="16" />
                </sdButton>
                <sdButton @click="navigateTo(`/${initial}/participants/campaign/${record.encrypt_id}`)" class="btn-icon" type="primary" shape="circle">
                  <sdFeatherIcons type="eye" size="16" />
                </sdButton>
              </div>
            </template>
            </a-table>
          </TableWrapper>
        </a-col>
      </a-row>
    </sdCards>
  </Main>
  <a-modal
    v-model:visible="deleteConfirmVisible"
    title="Participant remove confirmation"
    :confirm-loading="isDeleting"
    :centered="true"
    ok-text="Remove"
    cancel-text="Cancel"
    @ok="handleDeleteModal"
    :ok-button-props="okButtonProps"
  >
    <p>Are you sure you want to delete this participant?</p>
  </a-modal>
</template>
<script>

import moment from "moment";
import { TopToolBox, SearchInputStyled } from './Participants';
import { Main, TableWrapper } from '../../styled';
import { API } from "@/services/api";
import Notification from "@/services/Notification";

import { theme } from '@/config/theme/themeVariables';
import { status } from '@/config/config.js';

export default {
  name: "Participants",
  components: { TopToolBox, Main, TableWrapper, SearchInputStyled },
  computed: {
    initial: function() {
      return this.$store.getters.initial;
    },
    participant_status: function() {
      return status;
    }
  },
  data() {
    return {
      search: "",
      status: "",
      perPage: 10,
      totalData: 0,
      currentPage: 1,
      sortField: '',
      sortOrder: '',
      campaignName: '',
       deleteConfirmVisible: false,
      deleteId: '',
      isSearch: false,
      fetching: false,
      subscribers: [],
      filterKey: [{label: 'Winner', value: 1}, {label: 'Lost', value: 0}],
      fields: [
        { title: 'Id',dataIndex: 'id', key: 'id',sorter : true,sortDirection: "desc"},
        { title: 'Email',dataIndex: 'email',key: 'email',sorter : true},
        { title: 'Status',dataIndex: 'is_winner', key: 'is_winner',slots: {
            customRender: 'is_winner',
        },sorter : true},
        { title: 'Device',dataIndex: 'device',key: 'device',sorter : true},
        { title: 'Browser',dataIndex: 'browser',key: 'browser',sorter : true},
        { title: 'Country', dataIndex: 'country',key: 'country',sorter : true },
        { title: 'Date', dataIndex: 'created_date',key: 'created_date',
          slots: { customRender: 'created_date' },sorter : true
        },
        { title: 'Action',dataIndex: 'action', key: 'action',slots: {
          customRender: 'action',
        },},
      ],
      okButtonProps: {
        style: {
          backgroundColor: theme['primary-color'],
          borderWidth: 0,
        },
      },
    };
  },
  
  methods: {
    navigateTo: function (path = "") {
      if (path) {
        this.$router.replace({path});
      }
    },
    async getCampaignsSubscribers() {
      try {
        this.fetching = true;
        const params = {
          campaign_id: this.$route.params.campaign_id,
          page: this.currentPage,
          limit: this.perPage,
          search: this.search,
          status: this.status,
          sort_field: this.sortField,
          sort_order: this.sortOrder,
        };
        const datRes = await API.getSubacribers({ params });
        const { data: sData, status, message,campaign_name } = datRes.data;
        if (!status && message) {
          Notification.show(message, {type: "error",title: "Error"});
        } else {
          this.subscribers = sData.subscribers;
          this.totalData = sData.total_rows;
          this.campaignName = campaign_name;
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error",title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    clikOnPage(bvEvent, page) {
      bvEvent.preventDefault();
      this.currentPage = page;
      this.getCampaigns();
    },
    onSearching(text) {
      this.search = text;
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getCampaignsSubscribers();
      }, 500);
    },
    handleChangeForFilter(e) {
      if (this.status != e.target.value) {
        this.status = e.target.value;
        setTimeout(() => {
          this.getCampaignsSubscribers();
        }, 300);
      }
    },
    handleTableChange(page, filters, sorter) {
      if (this.currentPage != page.current) {
        this.currentPage = page.current;
        setTimeout(() => {
          this.getCampaignsSubscribers();
        }, 300);
      }
      if(sorter.field != "" && sorter.order != "" ) {
        this.sortField = sorter.field;
        this.sortOrder = "asc";
        if(sorter.order != "" && sorter.order == "descend") {
          this.sortOrder = "desc";
        }
        this.getCampaignsSubscribers();
      }
    },
    async handleDeleteModal() {
      try {
        const delRes = await API.deleteSubacribers({id: this.deleteId});
        const {status, message} = delRes.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          this.getCampaignsSubscribers();
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.deleteConfirmVisible = false;
      }
    },
    getFormatedDate(date = "") {
      return moment(date).format("LL");
    },
    onRemovePress(id) {
      this.deleteId = id;
      this.deleteConfirmVisible = true;
    },
    getStatusColor(status) {
      if(status == 1) {
        return 'Success';
      } else if(status == 0) {
        return 'error';
      } else if(status == 2) {
        return 'warning';
      } else {
        return '';
      }
    }
  },
  created() {
    this.getCampaignsSubscribers();
  }
}

</script>

<style>
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: #c72c51 !important;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked span {
  color: #fff !important;
}
.ant-radio-group-outline {
  padding : 0 !important;
}
.table-toolbox-menu .ant-radio-button-wrapper {
  padding : 0 25.25px !important;
}
.ant-radio-button-wrapper:not(:last-child):after {
  display : none !important;
}

</style>